import { APIProvider } from '@vis.gl/react-google-maps';
import { FC, PropsWithChildren, useEffect } from 'react';
import { CONFIGURATIONS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { IGoogleMapsConfig } from 'types/api';

export const useGoogleMapsApiKey = () => {
	// ! http clients
	const fetchApiKeyClient = useHttpClient<IGoogleMapsConfig>();

	// ! effects
	useEffect(() => {
		fetchApiKeyClient.request({
			requestConfig: CONFIGURATIONS_API.getMapConfig(),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		mapApiKey: fetchApiKeyClient.response?.apiKey,
		mapApiKeyLoading: fetchApiKeyClient.isLoading,
	};
};

const GoogleMapsProvider: FC<PropsWithChildren> = ({ children }) => {
	const googleData = useGoogleMapsApiKey();

	if (!googleData?.mapApiKey) {
		return null;
	}

	return (
		<APIProvider
			apiKey={googleData.mapApiKey}
			libraries={['places', 'geometry']}
		>
			{children}
		</APIProvider>
	);
};

export default GoogleMapsProvider;
