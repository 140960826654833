import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatches } from 'react-router-dom';
import { ABSOLUTE_ROUTES, INavMenuRoute, IRouteObjectHandle } from 'configs/routes';
import { useAuthenticatedInformation, useSettings } from 'hooks';
import { debounce } from 'utils/debounce';
import { IVendorUserAssociatedBranches } from 'types/api';
import { NavContentLabel } from './components/NavContentLabel';
import { NavLinkLabelWrapper } from './components/NavLinkLabelWrapper';
import { useNavVendor } from './useNavVendor';
import { Input } from 'antd';
import { ItemType } from 'antd/es/menu/interface';

export const useNavMenuController = (items: INavMenuRoute[]) => {
	const matches = useMatches();
	const { user } = useAuthenticatedInformation();
	const { t: tCommon } = useTranslation('common');
	const { language } = useSettings();

	const { generateNavItem, filteredVendors } = useNavVendor();

	const [filteredBranches, setFilteredBranches] = useState(user.stores);

	const convertIRouteToMenuItemType = useCallback(
		(route: INavMenuRoute, index: number): ItemType => {
			const { id, path, translationKey, children, icon } = route;

			const routeKey = (translationKey ?? path ?? `nav_item_${index}`).replaceAll('-', '_');

			const item = {
				key: routeKey,
				icon,
				label: (
					<div style={{ textTransform: 'capitalize' }}>
						{path ? (
							<NavLinkLabelWrapper route={route}>
								<NavContentLabel translationKey={translationKey} />
							</NavLinkLabelWrapper>
						) : (
							<NavContentLabel translationKey={translationKey} />
						)}
					</div>
				),
				children: children?.map(convertIRouteToMenuItemType),
			};

			if (id === 'vendor-management') {
				return generateNavItem({
					item,
					route,
					translationKey,
					detailsPath: ABSOLUTE_ROUTES.VENDOR_MANAGEMENT_DETAILS_TAB,
					key: 'vendor',
				});
			}

			if (id === 'campaign-management') {
				return generateNavItem({
					item,
					route,
					translationKey,
					detailsPath: ABSOLUTE_ROUTES.CAMPAIGN_DETAILS,
					key: 'campaigns',
				});
			}

			if (id === 'branch' && user.stores.length > 1) {
				const handleBranchSearch = (searchedString: string) => {
					let newFilteredBranches: IVendorUserAssociatedBranches[] = user.stores;

					if (searchedString?.length) {
						const lowerSearchString: string = searchedString.toLocaleLowerCase();
						newFilteredBranches = newFilteredBranches.filter(
							({ name, name_ar, id }) =>
								name?.toLocaleLowerCase().includes(lowerSearchString) ||
								name_ar?.toLocaleLowerCase().includes(lowerSearchString) ||
								id.toString().includes(lowerSearchString)
						);
					}

					setFilteredBranches(newFilteredBranches);
				};
				const debouncedHandleBranchSearch = debounce(handleBranchSearch, 400);
				// MULTI BRANCH USER
				// remove link to single branch details
				item.label = <NavContentLabel translationKey='branches' />;
				// add nav links to each store
				item.children = [
					...filteredBranches.map((store) => ({
						key: 'branch/' + store.id,
						label: <NavLink to={route.path + '/' + store.id}>{`#${store.id} ${store.name}`}</NavLink>,
					})),
				];

				// add search bar
				if (user.stores.length > 10) {
					item.children.unshift({
						key: 'search-branches',
						label: (
							<Input.Search
								allowClear
								placeholder={tCommon('nav_items.placeholders.search_branches')}
								onSearch={handleBranchSearch}
								onChange={(e) => debouncedHandleBranchSearch(e.target.value)}
							/>
						),
					});
				}

				return item;
			}

			return item;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filteredBranches, filteredVendors, user.stores, user.vendors, language]
	);

	const navItems: ItemType[] = useMemo(() => {
		return items.map(convertIRouteToMenuItemType);
	}, [items, convertIRouteToMenuItemType]);

	const selectedNavItems = useMemo(() => {
		const snakedMatches = matches.map((match) => {
			match.id = (match.handle as IRouteObjectHandle['handle'])?.translationKey || match.id.replaceAll('-', '_');
			return match;
		});

		const lastMatchedRoute = snakedMatches[snakedMatches.length - 1];

		const dynamicBranchMatch = snakedMatches.find((match) => match.id === 'branch');
		if (dynamicBranchMatch) {
			return ['branch/' + lastMatchedRoute.params.branchId];
		}

		const dynamicVendorMatch = snakedMatches.find((match) => match.id === 'vendor_management');
		if (dynamicVendorMatch && user.vendors.length !== 1) {
			return ['vendor/' + lastMatchedRoute.params.vendorId];
		}

		const dynamicCampaignMatch = snakedMatches.find((match) => match.id === 'campaign_management');
		if (dynamicCampaignMatch && user.vendors.length !== 1) {
			return ['campaigns/' + lastMatchedRoute.params.vendorId];
		}

		return snakedMatches.map((route) => route.id);
	}, [matches, user.vendors.length]);

	return { selectedNavItems, navItems };
};
