import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EFileType, EStatus, ESupportedLanguages, ISelectOption, ISupportedLanguage } from 'types/common';
import { THourRanges } from 'components/HoursFilterGroup';
import TranslatedLabel from 'components/TranslatedLabel';
import { DescriptionsProps, ModalProps, Tooltip } from 'antd';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';
import noImgLogo from 'assets/images/no-image.svg';

export const SELECTED_BRANCH_ID_STORAGE_KEY = 'selected_branch_id';
export const APP_LANGUAGE_STORAGE_KEY = 'vendor_dashboard_selected_app_language';
export const TIMEZONE_STORAGE_KEY = 'selected_timezone';

export const ID_COLUMN_WIDTH = '100px';

export const REFRESH_DATA_TTL = 30000; // ms

export const SEARCH_DEBOUNCE_TIMER = 500; // ms

export const DEFAULT_EMPTY_VALUE_PLACEHOLDER = '--';

export const FILE_FORMAT_NAMES: Record<EFileType, string> = {
	[EFileType.JPEG]: 'JPEG',
	[EFileType.PNG]: 'PNG',
	[EFileType.PDF]: 'PDF',
};

// ! languages
export const SUPPORTED_LANGUAGES: Record<ESupportedLanguages, ISupportedLanguage> = {
	[ESupportedLanguages.ENGLISH]: {
		name: 'English',
		iso_two_code: ESupportedLanguages.ENGLISH,
		flag: 'https://cdn.ipregistry.co/flags/noto/us.png',
		defaultDirection: 'ltr',
		locale: enUS,
	},
	[ESupportedLanguages.ARABIC]: {
		name: 'Arabic',
		iso_two_code: ESupportedLanguages.ARABIC,
		flag: 'https://cdn.ipregistry.co/flags/noto/kw.png',
		defaultDirection: 'rtl',
		locale: arEG,
	},
};

export const LANGUAGES_SELECT_OPTIONS: ISelectOption[] = Object.values(SUPPORTED_LANGUAGES).map((language) => ({
	value: language.iso_two_code,
	label: (
		<TranslatedLabel
			nameSpace='common'
			i18nKey={`languages.${language.iso_two_code}`}
		/>
	),
}));

// ! google maps

export const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
	lat: 29.35799231499003,
	lng: 47.98062606763254,
};

export const getGoogleMapsExternalLink = (latLng: string): string => {
	return `https://www.google.com/maps/search/?api=1&query=${latLng}`;
};

// ! filters
export const DEFAULT_HOURS_FILTERS: THourRanges[] = ['24', '48', '72', '168'];

// ! descriptions
export const DEFAULT_DESCRIPTIONS_PROPS: DescriptionsProps = {
	column: 1,
	size: 'small',
	styles: {
		label: { fontWeight: 600 },
	},
};

// ! modals
export const DEFAULT_MODAL_PROPS: ModalProps = {
	centered: true,
	getContainer: false,
	maskClosable: false,
	okText: <TranslatedLabel i18nKey='action_buttons.save' />,
};

// ! tags
export const DEFAULT_TAG_STYLES: CSSProperties = {
	userSelect: 'none',
	borderRadius: '4px',
	display: 'inline-flex',
	alignItems: 'center',
	textTransform: 'uppercase',
};

// ! tabs
export const DEFAULT_TABS_PROPS: CSSProperties = {
	height: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
};

// ! forms
export const DEFAULT_FORM_ITEMS_LAYOUT = {
	labelCol: { span: 6 },
	wrapperCol: { span: 12 },
};

export const COL_RESPONSIVE_CONFIG = {
	xs: 24,
	lg: 12,
};

// ! Google Maps styles
export const GLOBAL_MAP_CONTAINER_STYLE: CSSProperties = {
	width: '100%',
	height: '100%',
	border: '1px solid var(--border-color)',
	position: 'relative',
};

export const STATUS_NEW_VALUE_CONFIG: Record<EStatus, EStatus> = {
	[EStatus.ACTIVE]: EStatus.INACTIVE,
	[EStatus.INACTIVE]: EStatus.ACTIVE,
};

// ! phone-number strength
export const PHONE_NUMBER_REGEX_PATTERN = /^(?:[0-9] ?){6,14}[0-9]$/;

// ! password strength
export const PASSWORD_REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const PASSWORD_REGEX_PATTERN_ERROR_MESSAGE =
	'Please choose a stronger password (at least 8 characters, 1 lowercase, 1 uppercase, 1 numeric and 1 special).';

// ! table image cell
export const ImageCell: FC<{ imageSrc?: string }> = ({ imageSrc }) => {
	const { t: tCommonImage } = useTranslation('common', { keyPrefix: 'image' });
	if (!imageSrc)
		return (
			<div style={{ textAlign: 'center' }}>
				<Tooltip title={tCommonImage('no_image')}>
					<img
						src={noImgLogo}
						alt=''
						width={22}
						height={22}
					/>
				</Tooltip>
			</div>
		);

	const overlay = (
		<img
			src={imageSrc}
			alt={tCommonImage('alt_missing')}
			loading='lazy'
			style={{ maxWidth: '150px', maxHeight: '150px' }}
		/>
	);

	return (
		<Tooltip overlay={overlay}>
			<img
				loading='lazy'
				src={imageSrc}
				alt={tCommonImage('alt_missing')}
				style={{ maxWidth: '50px', maxHeight: '50px', objectFit: 'cover', color: 'var(--info-color)' }}
			/>
		</Tooltip>
	);
};
